import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
//import { header } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useOnclickOutside from "react-cool-onclickoutside";

import Logo from '../../assets/img/Logo-01-notext.png';
setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);


const Header = function ({ className }) {

  const [openMenu, setOpenMenu] = React.useState(false);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
  };
  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu2);
  };
  const handleBtnClick3 = () => {
    setOpenMenu3(!openMenu3);
  };
  const closeMenu = () => {
    setOpenMenu(false);
  };
  const closeMenu1 = () => {
    setOpenMenu1(false);
  };
  const closeMenu2 = () => {
    setOpenMenu2(false);
  };
  const closeMenu3 = () => {
    setOpenMenu3(false);
  };

  const ref = useOnclickOutside(() => {
    closeMenu();
  });
  const ref1 = useOnclickOutside(() => {
    closeMenu1();
  });
  const ref2 = useOnclickOutside(() => {
    closeMenu2();
  });
  const ref3 = useOnclickOutside(() => {
    closeMenu3();
  });


  const [showmenu, btn_icon] = useState(false);
  const [showpop, btn_icon_pop] = useState(false);
  const [shownot, btn_icon_not] = useState(false);
  const closePop = () => {
    btn_icon_pop(false);
  };
  const closeNot = () => {
    btn_icon_not(false);
  };
  const refpop = useOnclickOutside(() => {
    closePop();
  });
  const refpopnot = useOnclickOutside(() => {
    closeNot();
  });

  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");

      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      } if (window.pageYOffset > sticky) {
        closeMenu();
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);
  return (
    <header className={`navbar white ${className}`} id="myHeader">
      <div className='container'>
        <div className='row w-100-nav' style={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}>
          <div className='logo px-0'>
            <div className='navbar-title navbar-item'>
              <Link to="/" target="_self" rel="noopener noreferrer">
                <img
                  src={Logo}
                  style={{ width: "3vh", height: "3vh", cursor: 'pointer' }}
                />
                <h5 className="text" style={{ "color": "white" }}> LinksList.io</h5>
              </Link>

            </div>


          </div>

          <BreakpointProvider>
            <Breakpoint xl>
              <div className='menu'>
                <div className='navbar-item'>
                  <div ref={ref}>
                    {/* <div className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick} onMouseLeave={closeMenu}>
                      Links
                      <span className='lines'></span>
                      {openMenu && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu}>
                            <a
                              class='social-icon-link github'
                              href="https://app.gitbook.com/s/dOhZJgfYGfNOYxiiJ2az/~/changes/KK8bdNQHJ9EvhOrO6hqh/"
                              target='_blank'
                              rel="noopener"
                              aria-label='Github'
                            >
                              Documentation
                            </a>
                            <Link to="/paddy" target="_self" rel="noopener noreferrer">
                              Example Bio
                            </Link>

                          </div>
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </Breakpoint>
          </BreakpointProvider>

          <div className='mainside'>

            {/* <div className="logout">
              <div className='d-flex justify-content-center'>
                <span onClick={() => window.open("#", "_self")} className="btn-main inline white lead mx-0">Login</span>
              </div>
              &nbsp;&nbsp;&nbsp;
              <button id="walletButton" className="btn-main">
                Register
              </button>
            </div> */}
          </div>

        </div>

      </div>
    </header>
  );
}
export default Header;